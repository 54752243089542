
@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

    table, thead, tbody, th, td, tr {
        display: block;
    }

    tr:nth-child(even) {
        background-color: #f2f2f2!important;
    }
    tr:nth-child(odd) {
        background-color: #fff!important;
    }

    td {
        border: 1px solid #232623;
        border-bottom: 1px solid #eee!important;
        position: relative;
        padding-left: 50%!important;
        padding-top:11px;
        word-wrap: break-word;
        vertical-align: bottom!important;
    }

    td:before {
        position: absolute;
        top: 10px;
        left: 6px;
        width: 45%;
        font-weight: bold;
        padding-right: 10px;
        white-space: nowrap;
    }

    td:nth-of-type(1):before { content: "Equipo 1"; }
    td:nth-of-type(2):before { content: "Equipo 2"; }
    td:nth-of-type(3):before { content: "Resultado"; }

}